import MoeFooter from "./MoeFooter.vue"
import MoeHeader from "./MoeHeader.vue"
import MoeNavigationBar from "./MoeNavigationBar.vue"
import MoeFloatingWindow from "./MoeFloatWindow.vue"

const components = [
    MoeFooter,
    MoeHeader,
    MoeNavigationBar,
    MoeFloatingWindow
]

export default {
    install(Vue) {
        components.map(component => {
            Vue.component(component.name.replace("BaseComponent", ""), component)
        })
    }
}
