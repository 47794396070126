/**
 * Created by zed on 2022/3/16
 * Copyright © 2017 深圳市萌盟网络科技有限公司. All rights reserved.
 *
 * 【辅助类】声明了所有子模块的getters、mutations、actions的常量值
 */

export const getters = {
  resource: {
    homeBG: 'resource/getHomeBG',
    aboutBG: 'resource/getAboutBG',
    contactBG: 'resource/getContactBG',
    home: 'site/setHome',
    mapIMG: 'resource/getMapIMG',
    facadeIMG: 'resource/getFacadeIMG',
  },
  site: {
    enterprise: 'site/getEnterprise',
    other: 'site/getOther',
    app: 'site/getApp',
    developer: 'site/getDeveloper',
    home: 'site/getHome',
    contact: 'site/getContact',
    about: 'site/getAbout',
  }
}

export const mutations = {
  site: {
    enterprise: 'site/enterprise',
    other: 'site/other',
    app: 'site/setApp',
    developer: 'site/setDeveloper',
    contact: 'site/setContact',
    about: 'site/setAbout',
  }
}

export const actions = {
  resource: {
    fetchResource: 'resource/fetchResource',
  },
  site: {
    fetchEnterprise: 'site/fetchEnterprise',
    fetchSite: 'site/fetchSite',
  }
}
