/**
 * Created by zed on 2021/9/16
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 配置文件 - 云宠智能APP
 */
const secure = process.env.VUE_APP_SECURE
const oss = `https://moemone-cms-test.oss-cn-shenzhen.aliyuncs.com/${secure}/site`

export var IMAGES = {
  logo: {
    plain: `${oss}/cloud_pet_full.png`,
    iconLight: `${oss}/logo_icon_light.png`,
    iconDark: `${oss}/logo_icon_dark.png`,
  },
  material: {
    menuLight: `${oss}/menu_light.png`,
    menuDark: `${oss}/menu_dark.png`,
    menuCloseLight: `${oss}/menu_close_light.png`,
    facade: `${oss}/about_facade.jpg`,
    mapLocation: `${oss}/about_map_location.png`,
  },
  backgroundImage: {
    home: `${oss}/bg_home.jpg`,
    partner: `${oss}/bg_partner.png`,
    contactUs: `${oss}/bg_about.jpg`,
    aboutUs: `${oss}/bg_about.jpg`,
  },
}

/* 导航栏选项，位于页眉右侧 */
export var NAV_ITEMS = [
  { title: '首页', path: '/home' },
  { title: '关于我们', path: '/about' },
  { title: '隐私政策', path: '/tos/privacy' },
  { title: '用户协议', path: '/tos/agreement' },
  { title: '联系我们', path: '/contact' },
]

/* 导航栏选项，位于页眉右侧 */
export var FOOTER_ITEMS = [
  { title: '关于我们', path: '/about' },
  { title: '隐私政策', path: '/tos/privacy' },
  { title: '用户协议', path: '/tos/agreement' },
  { title: '联系我们', path: '/contact' },
]

/* 二维码选项，位于页脚右侧 */
export var QRCODE_ITEMS = null
// export var QRCODE_ITEMS = [
//   { title: '客服', image: '../../src/assets/images/icon-wx1.png' },
//   { title: '公众号', image: '../../src/assets/images/icon-wx2.png' },
//   { title: '小程序', image: '../../src/assets/images/icon-wx3.png' },
// ]

export var DOWNLOAD_LINK = {
  ANDROID: 'https://a.app.qq.com/o/simple.jsp?pkgname=cn.logical.pet',
  IOS: 'https://apps.apple.com/cn/app/%E4%BA%91%E5%AE%A0%E6%99%BA%E8%83%BD/id1580890431',
}
