/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【资源】状态仓库
 */
import enterpriseApi from "@/api/enterprise"
import storage from "@/utils/storage/storage"

const PREFIX = process.env.VUE_APP_STORAGE_PREFIX
const HOME_BG = `${PREFIX}_HOME_BG`
const ABOUT_BG = `${PREFIX}_ABOUT_BG`
const CONTACT_BG = `${PREFIX}_CONTACT_BG`
const MAP_IMG = `${PREFIX}_MAP_IMG`
const FACADE_IMG = `${PREFIX}_FACADE_IMG`

const state = {
  homeBG: storage.get(HOME_BG),
  aboutBG: storage.get(ABOUT_BG),
  contactBG: storage.get(CONTACT_BG),
  mapIMG: storage.get(MAP_IMG),
  facadeIMG: storage.get(FACADE_IMG),
}

const getters = {
  getHomeBG: state => state.homeBG,
  getAboutBG: state => state.aboutBG,
  getContactBG: state => state.contactBG,
  getMapIMG: state => state.mapIMG,
  getFacadeIMG: state => state.facadeIMG,
}

const mutations = {
  setResource: (state, resource) => {
    // 更新各状态值
    state.homeBG = resource.homeBG.imageURL
    state.aboutBG = resource.aboutBG.imageURL
    state.contactBG = resource.contactBG.imageURL
    state.mapIMG = resource.mapIMG.imageURL
    state.facadeIMG = resource.facadeIMG.imageURL
    // 持久化存储各状态值（解决刷新后数据丢失问题）
    storage.set(HOME_BG, state.homeBG)
    storage.set(ABOUT_BG, state.aboutBG)
    storage.set(CONTACT_BG, state.contactBG)
    storage.set(MAP_IMG, state.mapIMG)
    storage.set(FACADE_IMG, state.facadeIMG)
  },
}

const actions = {
  // 获取数据
  async fetchResource({ commit }) {
    try {
      let aliases = ['homeBG', 'aboutBG', 'contactBG', 'mapIMG', 'facadeIMG']
      const resources = await enterpriseApi.readResource(aliases)
      console.log('【Store/Resources】获取资源成功：', resources.data)
      // 【状态管理】更新各状态值
      commit('setResource', resources.data)
    } catch (error) {
      console.log('【Store/Resources】获取资源失败：', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
