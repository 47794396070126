/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【站点】状态仓库
 */
import enterpriseApi from "@/api/enterprise"
import storage from "@/utils/storage/storage"

const PREFIX = process.env.VUE_APP_STORAGE_PREFIX
const ENTERPRISE = `${PREFIX}_ENTERPRISE`
const OTHER = `${PREFIX}_OTHER`
const APP = `${PREFIX}_APP`
const DEVELOPER = `${PREFIX}_DEVELOPER`
const HOME = `${PREFIX}_HOME`
const CONTACT = `${PREFIX}_CONTACT`
const ABOUT = `${PREFIX}_ABOUT`

const state = {
  // 企业数据
  enterprise: storage.get(ENTERPRISE),
  // 网站数据
  other: storage.get(OTHER),
  // 应用数据
  app: storage.get(APP),
  // 开发商数据
  developer: storage.get(DEVELOPER),
  // 首页
  home: storage.get(HOME),
  // 联系我们
  contact: storage.get(CONTACT),
  // 关于我们
  about: storage.get(ABOUT),
}

const getters = {
  getEnterprise: state => state.enterprise,
  getOther: state => state.other,
  getApp: state => state.app,
  getDeveloper: state => state.developer,
  getHome: state => state.home,
  getContact: state => state.contact,
  getAbout: state => state.about,
}

const mutations = {
  setEnterprise: (state, enterprise) => {
    state.enterprise = enterprise
    storage.set(ENTERPRISE, enterprise)
  },
  setOther: (state, other) => {
    state.other = other
    storage.set(OTHER, other)
  },
  setApp: (state, app) => {
    state.app = app
    storage.set(APP, app)
  },
  setDeveloper: (state, developer) => {
    state.developer = developer
    storage.set(DEVELOPER, developer)
  },
  setHome: (state, home) => {
    state.home = home
    storage.set(HOME, home)
  },
  setContact: (state, contact) => {
    state.contact = contact
    storage.set(CONTACT, contact)
  },
  setAbout: (state, about) => {
    state.about = about
    storage.set(ABOUT, about)
  }
}

const actions = {
  // 获取企业数据
  async fetchEnterprise(context) {
    try {
      let resp = await enterpriseApi.readEnterprise()
      let enterprise = resp.data
      console.log('【Store/Enterprise】企业信息：', enterprise)
      context.commit('setEnterprise', enterprise)
    } catch (error) {
      console.log('【Store/Enterprise】企业信息：', error)
    }
  },
  // 获取网站数据
  async fetchSite(context) {
    try {
      let resp = await enterpriseApi.readSite()
      let site = resp.data
      console.log('【Store/Site】站点信息：', site)
      // 组装数据
      context.commit('setOther', {
        company: site.company,
        logo: site.logoURL,
        logoDark: site.logoDarkURL,
        icp: site.icp,
        copyright: site.copyright,
      })
      // 组装参数
      let params = site.parameters
      context.commit('setApp', params.app)
      context.commit('setDeveloper', params.developer)
      context.commit('setHome', params.home)
      context.commit('setContact', params.contact)
      context.commit('setAbout', params.about)
    } catch (error) {
      console.log('【Store/Site】站点信息：', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
