<template>
  <div>
    <!-- 桌面端-->
    <header :class="['ad-header-container']" :style="{ height: height }">
      <div class="header-mask" :style="{ 'background-image': `url(${getBg()})` }"></div>
      <div class="banner-container">
        <slot />
      </div>
    </header>
    <!-- 移动端 -->
    <header :class="['ad-header-container-mobile']">
      <img :src="getBg()"
           :style="{ height: mobileHeight }"
           style="width: 100%; object-fit: cover"
      >
      <div class="banner-container">
        <slot />
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "MoeHeader",
  props: {
    bg: { type: String, default: "", require: true },
    height: { type: String, default: "780px" },
    mobileBg: { type: String, default: "banner.png", require: true },
    mobileHeight: { type: String, default: "667px" },
  },
  data() {
    return {
      width: 0,
    }
  },
  created() {
    this.width = window.innerWidth
  },
  mounted() {},
  methods: {
    getBg() {
      return this.width <= 768 ? this.mobileBg : this.bg
    },
  },
}
</script>

<style lang="scss" scoped>
.ad-header-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  .header-mask {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &::after { /* 背景图加遮罩 */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1a1a1a;
      opacity: 0.55;
      content: "";
    }
  }

  .banner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1180px;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
  }
}
.ad-header-container-mobile {
  display: none;
}
@media (max-width: 768px) {
  .ad-header-container {
    display: none !important;
  }
  .ad-header-container-mobile {
    display: block !important;
    width: 100%;
    position: relative;
    .banner-container {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      box-sizing: border-box;
      margin: 0px auto;
    }
  }
}
</style>
