<template>
  <div>
    <div :class="['nav-container', fixedValue ? 'fixed' : '', !fixed ? 'fixed' : '']">
      <!-- 桌面端 -->
      <div class="nav">
        <div class="logo">
          <img v-if="fixed" :src="fixedValue ? logoDark : logo">
          <img v-if="!fixed" :src="logoDark">
        </div>
        <div class="menu">
          <span v-for="(item, index) in navItems" :key="index" class="menu-item">
            <a :class="[type === 'zp' ? '' : 'el-zp']" :href="item.path">{{ item.title }}</a>
            <span v-if="index < navItems.length - 1">|</span>
          </span>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="nav nav-mobile">
        <div class="logo">
          <img v-if="fixed" :src="fixedValue ? mobileLogo : mobileLogoDark" @click="goHome()">
          <img v-if="!fixed" :src="mobileLogo" @click="goHome()">
        </div>
        <div class="menu">
          <img v-if="fixed" :src="fixedValue ? menuDark : menuLight" @click="show = true">
          <img v-if="!fixed" :src="menuDark" @click="show = true">
        </div>
      </div>
    </div>
    <div v-if="show" class="menu-list">
      <div class="company-info">
        <div class="logo">
          <img :src="mobileLogo" @click="goHome()">
        </div>
        <div class="menu">
          <img :src="menuCloseLight" @click="show = false">
        </div>
      </div>
      <div class="navs">
        <div v-for="(item, index) in navItems" :key="index" class="nav-item">
          <span />
          <a :href="item.path">{{ item.title }}</a>
        </div>
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import { NAV_ITEMS, IMAGES } from "@/settings"
import { mapGetters } from "vuex"
import { actions, getters } from "@/store/helper"
import store from "@/store"

export default {
  name: "MoeNavigationBar",
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menuLight: IMAGES.material.menuLight,
      menuDark: IMAGES.material.menuDark,
      menuCloseLight: IMAGES.material.menuCloseLight,
      logo: null,
      logoDark: null,
      mobileLogo: null,
      mobileLogoDark: null,
      fixedValue: false,
      show: false,
      navItems: NAV_ITEMS,
    }
  },
  computed: {
    ...mapGetters({
      developerMap: getters.site.developer,
    }),
  },
  watch: {
    developerMap(newVal) {
      this.logo = newVal.logo
      this.logoDark = newVal.logoDark
      this.mobileLogo = newVal.mobileLogo
      this.mobileLogoDark = newVal.mobileLogoDark
    }
  },
  created() {
    store.dispatch(actions.site.fetchSite)
  },
  mounted() {
    if (this.fixed) {
      window.addEventListener("scroll", this.fixedActiveBtn)
      this.$nextTick(() => { this.fixedActiveBtn() })
    }
  },
  methods: {
    fixedActiveBtn() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.fixedValue = scrollTop > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-container {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  // 上划后样式效果
  &.fixed {
    background: #fff;
    .nav {
      .menu {
        .menu-item {}
        .el-zp {
          color: #222222;
          &:hover {
            color: #999999;
          }
        }
      }
    }
  }
  // 置顶时样式效果
  .nav {
    max-width: 1180px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1003;
    .logo {
      flex: 1;
      img {
        width: 168px;
        height: 68px;
        cursor: pointer;
        object-fit: contain;
      }
    }
    .menu {
      .menu-item {
        span {
          margin: 0px 20px;
          color: #ffffff;
        }
      }
      .el-zp {
        color: #ffffff;
        &:hover {
          color: #cccccc;
        }
      }
    }
  }
  .nav-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .menu-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: #000;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 0px 0px;
    z-index: 90000 !important;
    .company-info {
      height: 56px;
      display: flex;
      align-items: center;
      object-fit: contain;
      .logo {
        flex: 1;
        img {
          width: 24px;
          height: 24px;
          margin-left: 20px;
          object-fit: contain;
        }
      }
      .menu {
        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }
    }
    .navs {
      display: block !important;
      margin-top: 28px;
      padding: 0px 20px;
      a {
        display: block;
        font-size: 13px;

        font-weight: 400;
        color: #ffffff;
        text-decoration: none;
        margin: 18px 0px;
      }
      span {
        width: 100%;
        height: 1px;
        display: block;
        border-top: 1px solid #494e5e;
      }
    }
  }
  .nav-container {
    height: 50px;

    .nav {
      width: 100% !important;
      max-width: 100% !important;
      display: none;
    }
    .nav-mobile {
      display: flex;
      .logo {
        img {
          width: 24px;
          height: 24px;
          margin-left: 20px;
          object-fit: contain;
        }
      }
      .menu {
        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
