/**
 * Created by zed on 2021/10/11
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【数据模拟】云宠智能 - 应用官网
 */
import Mocker from "@/mock/mocker"

const prefix = process.env.VUE_APP_BASE_API
const secure = process.env.VUE_APP_SECURE
const oss = `https://moemone-cms-test.oss-cn-shenzhen.aliyuncs.com/${secure}/site`

// 产品介绍
Mocker.getSuccess(`${prefix}/api/introduce`, [
  {
    title: '远程操控，轻松易用',
    content: '手机一键绑定，您可以轻松掌握设备添加与操作，随心远程操控智能产品。',
    image: `${oss}/icon_introduce_remote.png`,
  },
  {
    title: '智能便捷，互联互通',
    content: '在APP上查看每个设备的运行状态。实时了解宠物的情况，让高品质的产品走进爱宠生活。',
    image: `${oss}/icon_introduce_connect.png`,
  },
  {
    title: '设备分享，趣味传递',
    content: '分享设备给家人和朋友，共同享受科技带来的乐趣。',
    image: `${oss}/icon_introduce_app.png`,
  },
])

// 合作伙伴
Mocker.getSuccess(`${prefix}/api/partners`, null)

// 招聘数据
Mocker.getSuccess(`${prefix}/api/jobs`, null)
