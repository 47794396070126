/**
 * Created by zed on 2021/10/9
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【路由模块】云宠智能 - 应用官网
 */
const app_cloudpets_net = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: '云宠智能'
    }
  },
  {
    path: '/home',
    name: '首页',
    component: () =>
      import ('@/views/app-site/home.vue'),
    meta: {
      title: '用心关爱，智能养宠'
    }
  },
  {
    path: '/about',
    name: '关于我们',
    component: () =>
      import ('@/views/app-site/about.vue'),
    meta: {
      title: '云宠-关于我们'
    }
  },
  {
    path: '/contact',
    name: '联系我们',
    component: () =>
      import ('@/views/app-site/contact.vue'),
    meta: {
      title: '云宠-联系我们'
    }
  },
  {
    path: '/tos/agreement',
    name: '用户试用协议',
    component: () =>
      import ('@/views/app-site/agreement.vue'),
    meta: {
      title: '云宠-用户协议'
    }
  },
  {
    path: '/tos/privacy',
    name: '隐私协议',
    component: () =>
      import ('@/views/app-site/privacy.vue'),
    meta: {
      title: '云宠-隐私协议'
    }
  },
  {
    path: '/download',
    name: '应用下载',
    component: () =>
      import ('@/views/download/download'),
    meta: {
      title: '应用下载页面'
    }
  },
]

export default app_cloudpets_net
